"use client";

import { useTextDirectionContext } from "../../../helpers/TextDirectionContext";
import classNames from "classnames/bind";
import { useEffect, useMemo, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";
import { useObserveElementRef } from "../../../helpers/in-viewport/in-viewport";
import { notNullish } from "../../../helpers/notNullish";
import { EnrichedBlocksCartoonsOccasionStack } from "../../../helpers/PageConfig";
import { createLinkForCartoonsHomepage } from "../../../helpers/createLinkForCartoonsHomepage";
import { CosmosButton } from "@cosmos/web/react";
import { SliderButton } from "../../slider-button/slider-button";
import { responsiveImageHelper } from "../../../helpers/responsiveImageHelper";
import styles from "./cartoons-occasion-stack.module.css";
import { notNull } from "../../../helpers/notNull";
import { isImage } from "../../../helpers/typeguards";
import { useLocaleContext } from "../../../helpers/LocaleContext";

const cx = classNames.bind(styles);

type BackgroundAssetType = "landscape" | "cityscape";
type TimeOfDay = "morning" | "noon" | "evening" | "night";

type Slide = {
  id: string;
  title: string | null;
  description: string | null;
  image?: string | null;
  timeOfDay: TimeOfDay;
  crepoOrder?: string | null;
  crepoDaySegment: string; // "morning1" | "morning2" | "noon1" | "noon2" | "evening1" | "evening2" | "night1" | "night2";
  link: string | null;
};

/**
 * When we moved from Cartoons API to Crepo API, the "chapters" were removed and RB added times of day.
 * Previously the "chapters" (activities like "fitness") would be assigned a time of day via our code.
 * RB added twice as many times of day (before we had `morning`, now `morning1`, `morning2`),
 * It was a business decision to have Multiple states for the morning (Sun rising, Sun rising a bit more),
 * in each state there's an order as well.
 * At the moment we don't make use of these and remap them to the existing time of day system.
 */

/**
 * These "chapters" are mostly old and can problably be removed...
 * We currently don't have an exact list of the "chapters" that can be entered into Red Bull's system, so this might need to be updated as needed.
 * Original List: Fitness, Drive, Work Blue Collar, Work White Collar, Sport, Study, Free Time On My Own, Gaming, Party & Socialising.
 * We added some popular Big Moments to this list, but there was some ambiguity to those instructions,
 * so as a fallback "Summer/Winter Solstice" and "Christmas & New Year" (which was the list provided), also has "Summer Solstice", "Winter Solstice", "Christmas", "New Years" as we know there are some "Big Moments" named like that.
 */
const getTimeOfDayForChapter = (chapter: string): TimeOfDay => {
  switch (chapter) {
    case "morning1":
    case "morning2":
    case "Fitness":
    case "Drive":
    case "Work Blue Collar":
    case "Easter":
    case "International Dance Day":
    case "International Friendship Day": {
      return "morning";
    }
    case "noon1":
    case "noon2":
    case "Work White Collar":
    case "Sport":
    case "Study":
    case "Global Recycling Day":
    case "Global Run":
    case "Summer/Winter Solstice":
    case "Summer Solstice":
    case "Winter Solstice": {
      return "noon";
    }
    case "evening1":
    case "evening2":
    case "Free Time On My Own":
    case "Gaming":
    case "Formula 1":
    case "Global Football Events":
    case "Winter Olympics":
    case "Summer Olympics": {
      return "evening";
    }
    case "night1":
    case "night2":
    case "Party & Socialising":
    case "Ramadan":
    case "Halloween":
    case "Lunar New Year":
    case "Diwali":
    case "Christmas & New Year":
    case "Christmas":
    case "New Years": {
      return "night";
    }
    default: {
      /* We might want to report an error to Sentry or something similar in the future */
      // throw new Error(`Could not derive a time-of-day for chapter: ${chapter}`);
      return "morning";
    }
  }
};

/**
 * When you scroll the stack into the view, the rail should animate to the slide
 * associated with the user's current time-of-day. This function finds the first
 * card that matches the browser's date (time) and returns its index.
 */
const getStartIndexFromTimeOfDay = (slides: Slide[]) => {
  const hoursElapsed = new Date().getHours();
  const NOON = 12;
  const EVENING = 17;
  const NIGHT = 20;

  if (!hoursElapsed) {
    return;
  }

  if (hoursElapsed < NOON) {
    return 1; // to trigger an animation we need to differentiate from initial state
  }

  if (hoursElapsed < EVENING) {
    return slides?.findIndex((slide) => slide.timeOfDay === "noon");
  }

  if (hoursElapsed < NIGHT) {
    return slides?.findIndex((slide) => slide.timeOfDay === "evening");
  }

  return slides?.findIndex((slide) => slide.timeOfDay === "night");
};

export const CartoonsOccasionStack = ({
  block,
}: {
  block: EnrichedBlocksCartoonsOccasionStack;
}) => {
  const textDirection = useTextDirectionContext();
  const locale = useLocaleContext();

  // Foreground and Background elements move in different directions, this needs to be swapped for RTL languages
  const landscapeDir = textDirection === "rtl" ? "ltr" : "rtl";
  const cityscapeDir = textDirection;
  const mainCarouselDir = textDirection;

  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);

  const slides = useMemo(
    () =>
      block.dotcomContent
        .filter(notNullish)
        .map<Slide>((occasion): Slide => {
          const image = occasion.featuredMedia?.filter(notNull).find(isImage);

          const mediaUrl =
            image?.imageEssence?.__typename === "CloudinaryImage"
              ? image.imageEssence.imageURL
              : image?.imageSrc;

          const resource = mediaUrl;

          /**
           * It used to be that every slide had a unique URL, RB removed the Cartoon sub pages,
           * We could simplify this code, but leaving it in for now in case that changes or button tracking etc
           * const link: createLinkForCartoonsPage(occasion),
           */

          const link = createLinkForCartoonsHomepage(locale);

          // Extract day_segment
          const daySegment =
            occasion.customProperties?.elements?.find(
              (item) => item?.key === "day_segment",
            )?.value?.text ?? "morning1";

          // Extract order if available
          const orderItem = occasion.customProperties?.elements?.find(
            (item) => item?.key === "order",
          );
          const orderValue = orderItem ? orderItem.value?.text : undefined;

          return {
            id: occasion.id,
            title: occasion.title?.text ?? "",
            description: occasion.teaser?.text ?? "",
            image: resource,
            timeOfDay: getTimeOfDayForChapter(daySegment),
            crepoOrder: orderValue,
            crepoDaySegment: daySegment,
            link: link,
          };
        })
        .sort((a, b) => {
          // Sort by "morning1" (then sub sort by "order"), morning2 (order), noon1 (order)...

          // Define the order of crepoDaySegment groups
          const crepoDaySegmentOrder: { [key: string]: number } = {
            morning1: 0,
            morning2: 1,
            noon1: 2,
            noon2: 3,
            evening1: 4,
            evening2: 5,
            night1: 6,
            night2: 7,
          };

          // Get the order for each crepoDaySegment
          const aCrepoDaySegmentOrder = crepoDaySegmentOrder[a.crepoDaySegment];
          const bCrepoDaySegmentOrder = crepoDaySegmentOrder[b.crepoDaySegment];

          // If crepoDaySegment is the same, sort by crepoOrder
          if (aCrepoDaySegmentOrder === bCrepoDaySegmentOrder) {
            const aCrepoOrder = a.crepoOrder || "1";
            const bCrepoOrder = b.crepoOrder || "1";
            return aCrepoOrder.localeCompare(bCrepoOrder); // Sort alphabetically
          }

          // Otherwise, sort by crepoDaySegment order
          return aCrepoDaySegmentOrder - bCrepoDaySegmentOrder;
        }) ?? [],

    [block, locale],
  );

  const [mainCarouselRef, mainCarousel] = useEmblaCarousel({
    speed: 5,
    loop: true,
    draggable: true,
    direction: mainCarouselDir,
  });

  const [cityscapeCarouselRef, cityscapeCarousel] = useEmblaCarousel({
    speed: 5,
    loop: true,
    draggable: false,
    direction: cityscapeDir,
  });

  const [landscapeCarouselRef, landscapeCarousel] = useEmblaCarousel({
    speed: 5,
    loop: true,
    draggable: false,
    direction: landscapeDir,
  });

  useEffect(() => {
    if (mainCarousel) {
      const onChangeSlide = () => {
        const selectedScrollSnap = mainCarousel?.selectedScrollSnap();

        cityscapeCarousel?.scrollTo(selectedScrollSnap);
        landscapeCarousel?.scrollTo(selectedScrollSnap);
        setCurrentSlideIndex(selectedScrollSnap);
      };

      mainCarousel.on("select", onChangeSlide);

      return () => {
        mainCarousel.off("select", onChangeSlide);
      };
    }
  }, [mainCarousel, block.sys.id, cityscapeCarousel, landscapeCarousel]);

  const onPrevNextButtonClick = (direction: "previous" | "next") =>
    direction === "previous"
      ? mainCarousel?.scrollPrev()
      : mainCarousel?.scrollNext();

  const getCartoonsAssetUrl = (
    key: string,
    options: { width?: number } = {},
  ) => {
    const transforms = ["f_auto"];
    if (options.width) {
      transforms.push(`w_${options.width}`);
    }
    const transformsString = transforms.join(",");
    return `https://res.cloudinary.com/hoiqxhyiy/image/fetch/${transformsString}/https://static-cartoons.redbull.com/occasionReel/occasionReel-${key}.png`;
  };

  const getBackgroundImageSrcSet = ({
    type,
    timeOfDay,
    nextTimeOfDay,
  }: {
    type: BackgroundAssetType;
    timeOfDay: TimeOfDay;
    nextTimeOfDay: TimeOfDay;
  }): string => {
    const variant = timeOfDay === nextTimeOfDay ? "1" : "2";

    /**
     * These sizes are more-or-less arbitrary, they were chosen because they
     * represent some typical viewport widths according to:
     * https://gs.statcounter.com/screen-resolution-stats
     *
     * More options results in more granular performance optimisation, but at the
     * cost of cache-hit probability.
     */
    const TYPICAL_VIEWPORT_WIDTHS = [
      320, 480, 768, 1024, 1200, 1366, 1440, 1536, 1920, 2560,
    ];

    return TYPICAL_VIEWPORT_WIDTHS.map((width) => {
      const url = getCartoonsAssetUrl(`${type}-${timeOfDay}${variant}`, {
        width,
      });

      return `${url} ${width}w`;
    }).join(", ");
  };

  const { ref, hasIntersected } = useObserveElementRef<HTMLDivElement>({
    threshold: 0,
  });

  useEffect(() => {
    if (hasIntersected && mainCarousel) {
      const index = getStartIndexFromTimeOfDay(slides);
      if (notNullish(index) && index !== -1) {
        mainCarousel.scrollTo(index);
      }
    }
  }, [hasIntersected, mainCarousel, slides]);

  const currentSlide = slides[currentSlideIndex];

  /**
   * You might ask - isn't this redundant? Isn't this the whole point of the
   * "loading=lazy" attribute?
   *
   * The issue is carousels might be scrolled *vertically* into the viewport,
   * but images that are *horizontally* positioned offscreen will still not
   * load until they're moved into view by interacting with the carousel.
   */
  const imageLoading = hasIntersected ? "eager" : "lazy";
  const morningCount = slides.filter(
    (element) => element.timeOfDay === "morning",
  ).length;
  const noonCount = slides.filter(
    (element) => element.timeOfDay === "noon",
  ).length;
  const eveningCount = slides.filter(
    (element) => element.timeOfDay === "evening",
  ).length;
  // const nightCount = slides.filter((element) => element.timeOfDay === "night").length;

  const arrayIncludingMorningLength = morningCount;
  const arrayIncludingNoonLength = morningCount + noonCount;
  const arrayIncludingEveningLength = morningCount + noonCount + eveningCount;
  // const arrayIncludingNightLength = morningCount + noonCount + eveningCount + nightCount;

  return (
    <div
      ref={ref}
      style={{
        "--_slide-timeofday-morning-index": currentSlideIndex + 1,
        "--_slide-timeofday-noon-index":
          currentSlideIndex + 1 - arrayIncludingMorningLength,
        "--_slide-timeofday-evening-index":
          currentSlideIndex + 1 - arrayIncludingNoonLength,
        "--_slide-timeofday-night-index":
          currentSlideIndex + 1 - arrayIncludingEveningLength,
      }}
      className={cx("container", `container--${currentSlide.timeOfDay}`)}
    >
      <div className={cx("inner")}>
        <div className={cx("content-intro")}>
          {block.title && <h2 className={cx("title")}>{block.title}</h2>}
          {block.description && (
            <p className={cx("description")}>{block.description}</p>
          )}
        </div>
        <div className={cx("carousels")}>
          <div className={cx("background-gradient-wrapper")} aria-hidden="true">
            <div
              className={cx(
                "background-gradient",
                `background-gradient--morning`,
                {
                  "background-gradient--is-active":
                    currentSlide.timeOfDay === "morning",
                },
              )}
            ></div>
            <div
              className={cx(
                "background-gradient",
                `background-gradient--noon`,
                {
                  "background-gradient--is-active":
                    currentSlide.timeOfDay === "noon",
                },
              )}
            ></div>
            <div
              className={cx(
                "background-gradient",
                `background-gradient--evening`,
                {
                  "background-gradient--is-active":
                    currentSlide.timeOfDay === "evening",
                },
              )}
            ></div>
            <div
              className={cx(
                "background-gradient",
                `background-gradient--night`,
                {
                  "background-gradient--is-active":
                    currentSlide.timeOfDay === "night",
                },
              )}
            ></div>
          </div>
          <div className={cx("header")}>
            <div className={cx("background-clouds")} aria-hidden="true">
              {(() => {
                const arr = [];
                const arrFilename = [
                  1, 2, 3, 4, 5,
                ]; /* cycle though these filenames if we have more than 5 clouds */
                for (let i = 0; i < 8; i++) {
                  arr.push(
                    <img
                      key={i}
                      src={getCartoonsAssetUrl(
                        `cloud${(i % arrFilename.length) + 1}`,
                      )}
                      alt=""
                      aria-hidden="true"
                      className={cx(
                        "background-cloud",
                        `background-cloud--${i + 1}`,
                      )}
                      loading={imageLoading}
                    />,
                  );
                }
                return arr;
              })()}
            </div>
            <div className={cx("satellites")} aria-hidden="true">
              <div className={cx("satellite", "satellite--sun")}>
                <img
                  className={cx("satellite-img")}
                  alt=""
                  src={getCartoonsAssetUrl("sun")}
                  loading={imageLoading}
                />
              </div>
              <div className={cx("satellite", "satellite--moon")}>
                <img
                  className={cx("satellite-img")}
                  alt=""
                  src={getCartoonsAssetUrl("moon")}
                  loading={imageLoading}
                />
              </div>
            </div>
            {/* ↓ Visually this is a H3, but set as a Div as it's only her for visual design, the real h3 is visually hidden within each slide */}
            <div aria-hidden="true" className={cx("slide-title")}>
              {/* We list all titles here, overlapping in a single cell Grid to force max height */}
              {slides.map((slide, index) => (
                <span
                  key={slide.id}
                  className={cx("slide-title-item", {
                    "slide-title-item--is-active": index === currentSlideIndex,
                  })}
                  hidden={currentSlideIndex === index ? false : true}
                >
                  {slide.title}
                </span>
              ))}
            </div>
          </div>
          <div className={cx("carousel-stage")}>
            <div
              className={cx(
                "backgrounds-wrapper",
                "backgrounds-wrapper--landscape",
              )}
              dir={landscapeDir}
              ref={landscapeCarouselRef}
              aria-hidden="true"
            >
              <div className={cx("backgrounds")}>
                {slides.map((slide, index) => (
                  <div key={slide.id} className={cx("background")}>
                    <img
                      className={cx("background-image")}
                      alt=""
                      loading={imageLoading}
                      srcSet={getBackgroundImageSrcSet({
                        type: "landscape",
                        timeOfDay: slide.timeOfDay,
                        nextTimeOfDay:
                          slides[(index + 1) % slides.length].timeOfDay,
                      })}
                      sizes="min(1222px, 100vw)"
                    />
                  </div>
                ))}
              </div>
            </div>
            <div
              className={cx(
                "backgrounds-wrapper",
                "backgrounds-wrapper--cityscape",
              )}
              dir={cityscapeDir}
              ref={cityscapeCarouselRef}
              aria-hidden="true"
            >
              <div className={cx("backgrounds")}>
                {slides.map((slide, index) => (
                  <div key={slide.id} className={cx("background")}>
                    <img
                      className={cx("background-image")}
                      alt=""
                      loading={imageLoading}
                      srcSet={getBackgroundImageSrcSet({
                        type: "cityscape",
                        timeOfDay: slide.timeOfDay,
                        nextTimeOfDay:
                          slides[(index + 1) % slides.length].timeOfDay,
                      })}
                      sizes="min(1222px, 100vw)"
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className={cx("carousel-wrapper")}>
              <div
                className={cx("carousel")}
                dir={mainCarouselDir}
                ref={mainCarouselRef}
              >
                <div className={cx("carousel-slides")}>
                  {slides.map((slide, index) => {
                    if (slide) {
                      return (
                        <div
                          data-index={index}
                          data-length={slides.length - 1}
                          data-current={currentSlideIndex}
                          className={cx("carousel-slide", {
                            "carousel-slide--not-active":
                              index !== currentSlideIndex,
                          })}
                          key={slide.id}
                        >
                          {/* Give each slide some semantic meaning */}
                          {slide.title && (
                            <h3
                              className={cx(
                                "carousel-title",
                                "carousel-title--sr-only",
                              )}
                            >
                              {slide.title}
                            </h3>
                          )}
                          {/* TODO: this link probably reads quite verbose in a screen reader */}
                          <a
                            className={cx("carousel-link")}
                            href={slide.link ?? undefined}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {slide.image && (
                              <div
                                className={cx({
                                  "carousel-slide--prev-wiggle":
                                    currentSlideIndex === 0
                                      ? index === slides.length - 1
                                      : index === currentSlideIndex - 1,
                                  "carousel-slide--next-wiggle":
                                    currentSlideIndex === slides.length - 1
                                      ? index === 0
                                      : index === currentSlideIndex + 1,
                                })}
                              >
                                <img
                                  className={cx("carousel-image", {
                                    "carousel-image--small":
                                      index !== currentSlideIndex,
                                  })}
                                  srcSet={responsiveImageHelper.createCrepoSrcSet(
                                    slide.image,
                                    locale,
                                  )}
                                  sizes="(min-width: 720px) 201px, 192px"
                                  loading={imageLoading}
                                  alt={slide.title ?? ""}
                                />
                              </div>
                            )}
                            {/* CMS has a description, currently it's not visually shown */}
                            {slide.description && (
                              <span
                                className={cx(
                                  "carousel-description",
                                  "carousel-description--sr-only",
                                )}
                              >
                                {slide.description}
                              </span>
                            )}
                          </a>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
            <SliderButton
              buttonKind="cover-up"
              kind="previous"
              className={cx("slider-button", "slider-button--prev")}
              onClick={() => onPrevNextButtonClick("previous")}
              accessibilityLabel="occasion reel button to previous occasion"
            />
            <SliderButton
              buttonKind="cover-up"
              kind="next"
              className={cx("slider-button", "slider-button--next")}
              onClick={() => onPrevNextButtonClick("next")}
              accessibilityLabel="occasion reel button to next occasion"
            />
          </div>
          <div className={cx("button-wrapper")}>
            <CosmosButton
              href={slides[currentSlideIndex]?.link ?? undefined}
              kind="cover-up"
              size="large"
              className={cx("button")}
            >
              {block.buttonText || "More Information"}
            </CosmosButton>
          </div>
        </div>
      </div>
    </div>
  );
};

import { CARTOONS_CLOUDINARY_IMAGE_BASE_URL } from "./constants";
import { getContentfulImageUrl } from "./images/getContentfulImageUrl";
import { getRbImageUrl } from "./images/getRbImageUrl";

/**
 * These sizes are more-or-less arbitrary, they were chosen because they
 * represent some typical viewport widths according to:
 * https://gs.statcounter.com/screen-resolution-stats
 *
 * More options results in more granular performance optimisation, but at the
 * cost of cache-hit probability.
 */
export const TYPICAL_VIEWPORT_WIDTHS = [
  320, 480, 768, 1024, 1200, 1366, 1440, 1536, 1920, 2560,
];

export const responsiveImageHelper = {
  createCrepoSrcSet: (source: string, locale: string) => {
    return TYPICAL_VIEWPORT_WIDTHS.map((width) => {
      const resizedSrc = getRbImageUrl(source, { width }, locale);

      return `${resizedSrc} ${width}w`;
    }).join(", ");
  },
  createCartoonsSrcSet: (source: string) => {
    /**
     * See:
     * https://cloudinary.com/documentation/transformation_reference
     */
    return TYPICAL_VIEWPORT_WIDTHS.map((width) => {
      const resizedSrc = source
        .replace("https://img.liiift.io", CARTOONS_CLOUDINARY_IMAGE_BASE_URL)
        .replace(/(\/im\/)/, `/im:i:w_${width},f_auto/`);

      return `${resizedSrc} ${width}w`;
    }).join(", ");
  },
  createContentfulSrcSet: (source: string) => {
    /**
     * See:
     * https://cloudinary.com/documentation/transformation_reference
     */
    return TYPICAL_VIEWPORT_WIDTHS.map((width) => {
      const resizedSrc = getContentfulImageUrl(source, { width });

      return `${resizedSrc} ${width}w`;
    }).join(", ");
  },
};

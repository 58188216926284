type ImageOptions = {
  width?: number;
  height?: number;

  /**
   * Aspect Ratio:
   * https://cloudinary.com/documentation/transformation_reference#ar_aspect_ratio
   *
   * Either:
   * - A string value in the form a:b, where a is the width and b is the height
   *   (e.g., 16:9).
   * - A decimal value representing the width divided by the height (e.g., 0.5).
   */
  ar?: `${number}:${number}` | number;

  /**
   * Gravity:
   * https://cloudinary.com/documentation/transformation_reference#g_gravity
   */
  g?: "auto";

  /**
   * Quality
   * https://cloudinary.com/documentation/transformation_reference#q_quality
   */
  q?: number | "auto";
};

export const getRbImageUrl = (
  media: string,
  options: ImageOptions = { width: 1000 },
  locale: string,
): string | undefined => {
  /**
   * CREPO image URLs come in two forms:
   * 1. Transforms already applied, eg:
   *  https://img.redbull.com/images/c_crop,w_5464,h_3643,x_0,y_1161,f_auto,q_auto/c_scale,w_150/redbullcom/2022/5/5/esxtfazwc5k0xntwv20i/max-verstappen-profile-pic
   * 2. With a placeholder ("{op}") for transforms to be passed, eg:
   *  https://img.redbull.com/images/{op}/redbullcom/2022/5/5/esxtfazwc5k0xntwv20i/max-verstappen-profile-pic
   *
   * This function needs to be able to handle both.
   */
  const url = media.replace(
    /(\/images\/).+(\/redbullcom\/)/,
    `$1${getImageUrlTransform(options)}$2`,
  );

  if (locale === "cn-zh") {
    return url.replace("img.redbull.com", "img.redbull.cn");
  }
  return url;
};

function getImageUrlTransform({
  height,
  width,
  ar,
  g,
  q = "auto",
}: ImageOptions) {
  /**
   * See:
   * https://cloudinary.com/documentation/transformation_reference
   */
  const options = ["c_fill", "f_auto"];

  if (q) {
    options.push(`q_${q}`);
  }

  if (width) {
    options.push(`w_${width}`);
  }
  if (height) {
    options.push(`h_${height}`);
  }
  if (width && height) {
    options.push("g_auto");
  }
  if (g) {
    options.push(`g_${g}`);
  }
  if (ar) {
    options.push(`ar_${ar}`);
  }

  return options.join(",");
}
